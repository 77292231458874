import React, { useEffect, useState, useContext } from 'react';
import Quotes from './Quotes';
import { useNavigate } from 'react-router-dom';
import { QuoteContext } from './../assets/context/QuoteContext';
import tailLift from '../assets/Taillift.svg'
import forkLift from '../assets/Fork Lift.svg'
import tailLiftLgt from '../assets/Taillift-lgt.svg'
import forkLiftLgt from '../assets/Fork Lift-lgt.svg'
import url from './url';
import countries from './countries';

function CalQuote() {
    const { saveQuote, saveFormData } = useContext(QuoteContext);
    const [isInternational, setIsInternational] = useState(false);
    const [originCountry, setOriginCountry] = useState('AU');
    const [destinationCountry, setDestinationCountry] = useState('AU');
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        pickupAddress: '',
        deliveryAddress: '',
        packageType: '',
        weight: '',
        goodsValue: '',
        dangerousGoods: '',
        pickupAddressType: '',
        deliveryAddressType: '',
        length: '',
        width: '',
        height: '',
        specialHandling: '',
        pickupPostcode: '',
        deliveryPostcode: '',
        pickupCity: '',
        deliveryCity: '',
        pickupState: '',
        deliveryState: '',
        originCountry: 'AU',
        destinationCountry: 'AU',
        palletStackable: null
    });

    console.log(formData);

    const shouldShowLoadingOptions = () => {
        const weight = parseFloat(formData.weight);
        return weight >= 26 || formData.packageType === 'Pallet';
    };
    const handleLoadingMethodChange = (type, method) => {
        setFormData(prev => ({
            ...prev,
            [`${type}LoadingMethod`]: method
        }));
    };

    const handlePalletStackableChange = (isStackable) => {
        setFormData(prev => ({
            ...prev,
            palletStackable: isStackable
        }));
    };

    const handleTabSwitch = (isInt) => {
        setIsInternational(isInt);
        if (!isInt) {
            setOriginCountry('AU');
            setDestinationCountry('AU');
            setFormData(prev => ({
                ...prev,
                originCountry: 'AU',
                destinationCountry: 'AU',
                pickupAddress: '',
                deliveryAddress: '',
                pickupPostcode: '',
                deliveryPostcode: '',
                pickupCity: '',
                deliveryCity: '',
                pickupState: '',
                deliveryState: ''
            }));

            const pickupInput = document.getElementById('pickup-address');
            const deliveryInput = document.getElementById('delivery-address');
            if (pickupInput) pickupInput.value = '';
            if (deliveryInput) deliveryInput.value = '';
        }
    };

    useEffect(() => {
        const initializeAutocomplete = async (inputId, type, countryCode) => {
            const input = document.getElementById(inputId);
            if (!input) {
                console.error(`Input element with id ${inputId} not found`);
                return;
            }

            const existingAutocomplete = input.getAttribute('data-autocomplete-instance');
            if (existingAutocomplete) {
                window.google.maps.event.clearInstanceListeners(input);
            }

            const autocomplete = new window.google.maps.places.Autocomplete(input, {
                types: ['address'],
                componentRestrictions: { country: countryCode.toLowerCase() },
                fields: ['address_components', 'formatted_address', 'geometry', 'name']
            });

            input.setAttribute('data-autocomplete-instance', 'true');

            autocomplete.addListener('place_changed', async () => {
                const place = autocomplete.getPlace();
                console.log('Selected place:', place);

                if (!place.address_components) {
                    console.error('No address components found in the selected place');
                    return;
                }

                let postalCode = '';
                let city = '';
                let state = '';

                place.address_components.forEach(component => {
                    const types = component.types;

                    if (types.includes('postal_code')) {
                        postalCode = component.long_name;
                    }
                    if (types.includes('locality') || types.includes('postal_town')) {
                        city = component.long_name;
                    }
                    if (types.includes('administrative_area_level_1')) {
                        state = component.short_name;
                    }
                });

                const formattedAddress = `${city}, ${state} ${postalCode}`.trim();

                setFormData(prev => ({
                    ...prev,
                    [`${type}Postcode`]: postalCode,
                    [`${type}City`]: city,
                    [`${type}State`]: state,
                    [`${type}Address`]: formattedAddress || input.value
                }));

                input.value = formattedAddress;
            });
        };

        if (typeof window !== 'undefined' && window.google && window.google.maps && window.google.maps.places) {
            initializeAutocomplete('pickup-address', 'pickup', originCountry);
            initializeAutocomplete('delivery-address', 'delivery', destinationCountry);
        } else {
            console.error('Google Maps API not loaded properly');
        }
    }, [originCountry, destinationCountry]);

    const handleOriginCountryChange = (e) => {
        const countryCode = e.target.value;
        setOriginCountry(countryCode);
        setFormData(prev => ({
            ...prev,
            originCountry: countryCode
        }));
    };

    const handleDestinationCountryChange = (e) => {
        const countryCode = e.target.value;
        setDestinationCountry(countryCode);
        setFormData(prev => ({
            ...prev,
            destinationCountry: countryCode
        }));
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [id]: value
        }));
    };

    const handleRadioChange = (e) => {
        const { name, id } = e.target;
        let value = '';

        if (name === 'caution') {
            value = id === 'noDanger' ? 'None' :
                id === 'l-battery' ? 'LithiumBattery' : 'Other';
        } else if (name === 'handling') {
            value = id === 'fragile' ? 'Fragile' :
                id === 'liquid' ? 'Liquid' : 'UnusualShape';
        }

        setFormData(prev => ({
            ...prev,
            [name === 'caution' ? 'dangerousGoods' : 'specialHandling']: value
        }));
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        saveFormData(formData);

        const isStackable = formData.packageType === 'Pallet' ? formData.palletStackable : false;

        const requiredFields = [
            { key: 'pickupAddress', label: 'Pickup Address' },
            { key: 'deliveryAddress', label: 'Delivery Address' },
            { key: 'packageType', label: 'Package Type' },
            { key: 'weight', label: 'Weight' },
            { key: 'goodsValue', label: 'Goods Value' },
            { key: 'pickupAddressType', label: 'Pickup Address Type' },
            { key: 'deliveryAddressType', label: 'Delivery Address Type' },
            { key: 'length', label: 'Length' },
            { key: 'width', label: 'Width' },
            { key: 'height', label: 'Height' },
        ];

        if (isInternational) {
            requiredFields.push(
                { key: 'originCountry', label: 'Origin Country' },
                { key: 'destinationCountry', label: 'Destination Country' }
            );
        }

        const missingFields = requiredFields
            .filter(field => !formData[field.key] || formData[field.key].trim() === '')
            .map(field => field.label);

        if (missingFields.length > 0) {
            alert(`Please fill the following required fields: ${missingFields.join(', ')}`);
            setIsLoading(false);
            return;
        }

        const getAustraliaDate = () => {
            const timeZone = "Australia/Sydney";
            const now = new Date();

            const australiaTime = new Date(
                now.toLocaleString("en-US", { timeZone })
            );

            const currentHour = australiaTime.getHours();

            if (currentHour >= 14) {
                australiaTime.setDate(australiaTime.getDate() + 1);
            }

            return australiaTime.toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
        };

        console.log(getAustraliaDate());

        const requestBody = {
            FromAddress: {
                CountryCode: isInternational ? formData.originCountry : "AU",
                City: formData.pickupCity,
                Postcode: formData.pickupPostcode,
                Province: formData.pickupState,
                AddressType: formData.pickupAddressType,
                TailLiftRequired: formData.weight >= 26 || formData.packageType === 'Pallet'
            },
            ToAddress: {
                CountryCode: isInternational ? formData.destinationCountry : "AU",
                City: formData.deliveryCity,
                Postcode: formData.deliveryPostcode,
                Province: formData.deliveryState,
                AddressType: formData.deliveryAddressType,
                TailLiftRequired: formData.weight >= 26 || formData.packageType === 'Pallet'
            },
            CollectionFromTime: `${getAustraliaDate()} 10:00`,
            BookingPackageType: "NonDocuments",
            DangerousGoods: formData.dangerousGoods,
            Warranty: 0,
            DeclaredCurrency: "AUD",
            DeclaredValue: Number(formData.goodsValue),
            AllDuties: true,
            CarrierServiceId: null,
            CarrierId: null,
            MeasurementUnitsSet: "KgCm",
            Items: [
                {
                    PackageType: formData.packageType.toLowerCase(),
                    Quantity: 1,
                    Weight: Number(formData.weight),
                    Length: Number(formData.length),
                    Width: Number(formData.width),
                    Height: Number(formData.height)
                }
            ],
            CarbonNeutral: false,
            NonstackableItems: isStackable,
            RemoveCommercialInvoice: false,
            DeliverySignatureType: "NotRequired"
        };

        console.log('Request Body:', requestBody);

        try {
            const response = await fetch(`${url}/api/quote`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            const data = await response.json();
            console.log('Quote Response:', data);
            if (data.Quotes.length > 0) {
                saveQuote(data, requestBody);
                navigate('/Quotes', { state: { data, requestBody } });
            } else {
                alert("There are no relevant Couriers for that service");
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching quote:', error);
            setIsLoading(false);
        }
    };

    const LoadingOptions = ({ type }) => {
        if (!shouldShowLoadingOptions()) return null;

        const isPickup = type === 'pickup';
        const loadingMethod = isPickup ? formData.pickupLoadingMethod : formData.deliveryLoadingMethod;

        return (
            <div className="quote-field inp-y-spc anything-dangerous-fields radio-btns">
                <div className="row">
                    <div className={`col-lg-${isPickup && formData.packageType === 'Pallet' ? '6' : '12'}`}>
                        <p className='mb-0'>Loading Method</p>
                        <div className="d-flex flex-row">
                            <div className='radio-optn-cntnr'>
                                <input
                                    type="radio"
                                    name={`${type}LoadingMethod`}
                                    id={`${type}TailLift`}
                                    checked={loadingMethod === 'tailLift'}
                                    onChange={() => handleLoadingMethodChange(type, 'tailLift')}
                                />
                                <label htmlFor={`${type}TailLift`}>Tail Lift</label>
                            </div>
                            <div className='radio-optn-cntnr'>
                                <input
                                    type="radio"
                                    name={`${type}LoadingMethod`}
                                    id={`${type}ForkLift`}
                                    checked={loadingMethod === 'forkLift'}
                                    onChange={() => handleLoadingMethodChange(type, 'forkLift')}
                                />
                                <label htmlFor={`${type}ForkLift`}>Fork Lift</label>
                            </div>
                        </div>
                    </div>
                    {isPickup && formData.packageType === 'Pallet' && (
                        <div className='col-lg-6 justify-content-between align-items-center'>
                            <p className='mb-0'>Pallet</p>
                            <div className="d-flex flex-row">
                                <div className='radio-optn-cntnr'>
                                    <input
                                        type="radio"
                                        name="pallet-stackable"
                                        id="stackable"
                                        checked={formData.palletStackable === true}
                                        onChange={() => handlePalletStackableChange(true)}
                                    />
                                    <label htmlFor="stackable">Stackable</label>
                                </div>
                                <div className='radio-optn-cntnr'>
                                    <input
                                        type="radio"
                                        name="pallet-stackable"
                                        id="non-stackable"
                                        checked={formData.palletStackable === false}
                                        onChange={() => handlePalletStackableChange(false)}
                                    />
                                    <label htmlFor="non-stackable">Non Stackable</label>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="container mt-5">
                <h2 className='mb-5'>Compare Domestic and International Quotes in Seconds</h2>
                <div className="cal-quote-form">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${!isInternational ? 'active' : ''}`}
                                onClick={() => handleTabSwitch(false)}
                                type="button"
                                role="tab"
                            >
                                Domestic
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${isInternational ? 'active' : ''}`}
                                onClick={() => handleTabSwitch(true)}
                                type="button"
                                role="tab"
                            >
                                International
                            </button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                            <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                                <div className="row gx-5">
                                    <div className="col-lg-6">

                                        {isInternational && (
                                            <div className="quote-field inp-y-spc">
                                                <label htmlFor="originCountry">From Origin Country</label>
                                                <select
                                                    id="originCountry"
                                                    value={originCountry}
                                                    onChange={handleOriginCountryChange}
                                                >
                                                    {countries.map(country => (
                                                        <option key={country.code} value={country.code}>
                                                            {country.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}

                                        <div className="quote-field inp-y-spc position-relative">
                                            <label htmlFor="pickup-address">Pickup Address</label>
                                            <input type="text" id='pickup-address' placeholder='Location or postal code' onChange={handleInputChange} />
                                            <div className="pickup-addr-suggestions addr-suggestions d-none">
                                                <span></span>
                                            </div>
                                        </div>
                                        <div className="inp-y-spc quote-field dimension-fields">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="d-flex flex-column">
                                                        <label htmlFor="pckg-type">Package Details</label>
                                                        <select
                                                            id="packageType"
                                                            value={formData.packageType}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option value="">Package Type</option>
                                                            <option value="Carton">Carton</option>
                                                            <option value="Bag">Bag</option>
                                                            <option value="Envelope">Envelope</option>
                                                            <option value="Crate">Crate</option>
                                                            <option value="Drum">Drum</option>
                                                            <option value="Roll">Roll</option>
                                                            <option value="Pallet">Pallet</option>
                                                            <option value="Box">Box</option>
                                                            <option value="Satchel">Satchel</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="d-flex flex-column">
                                                        <label htmlFor="pckg-weight">Weight in KGs</label>
                                                        <input
                                                            type="text"
                                                            id="weight"
                                                            placeholder="in KGs"
                                                            value={formData.weight}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="d-flex flex-column">
                                                        <label htmlFor="goodsValue">Goods Value in $</label>
                                                        <input
                                                            type="text"
                                                            id="goodsValue"
                                                            placeholder="Goods Value"
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="quote-field inp-y-spc anything-dangerous-fields radio-btns">
                                            <div className="row">
                                                <div className='col-lg-8'>
                                                    <p className='mb-0'>Anything Dangerous</p>
                                                    <div className="d-flex flex-row">
                                                        <div className='radio-optn-cntnr'>
                                                            <input type="radio" name="caution" id="noDanger" onChange={handleRadioChange} />
                                                            <label htmlFor="noDanger">No</label>
                                                        </div>
                                                        <div className='radio-optn-cntnr'>
                                                            <input type="radio" name="caution" id="l-battery" onChange={handleRadioChange} />
                                                            <label htmlFor="l-battery">Lithium Battery</label>
                                                        </div>
                                                        <div className='radio-optn-cntnr'>
                                                            <input type="radio" name="caution" id="OtherDanger" onChange={handleRadioChange} />
                                                            <label htmlFor="OtherDanger">Other</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 d-flex justify-content-between align-items-center'>
                                                    <select
                                                        className=''
                                                        id="pickupAddressType"
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="" disabled selected>Pickup Address Type</option>
                                                        <option value="Residential">Residential</option>
                                                        <option value="Commercial">Commercial</option>
                                                        <option value="Air & Sea Depot">Air & Sea Depot</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <LoadingOptions type="pickup" />
                                    </div>
                                    <div className="col-lg-6">
                                        {isInternational && (
                                            <div className="quote-field inp-y-spc">
                                                <label htmlFor="destinationCountry">To Destination Country</label>
                                                <select
                                                    id="destinationCountry"
                                                    value={destinationCountry}
                                                    onChange={handleDestinationCountryChange}
                                                >
                                                    {countries.map(country => (
                                                        <option key={country.code} value={country.code}>
                                                            {country.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                        <div className="quote-field inp-y-spc">
                                            <label htmlFor="delivery-address">Delivery Address</label>
                                            <input type="text" id='delivery-address' placeholder='Location or postal code' onChange={handleInputChange} />
                                            <div className="delivery-addr-suggestions addr-suggestions d-none">
                                                <span></span>
                                            </div>
                                        </div>
                                        <div className="inp-y-spc quote-field dimension-fields dim-fields">
                                            <label htmlFor="">Dimensions in CM <span> (Volume 0.000m)</span></label>
                                            <div className="d-flex justify-content-between">
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <input
                                                            type="text"
                                                            id="length"
                                                            placeholder="Length"
                                                            value={formData.length}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <input
                                                            type="text"
                                                            id="width"
                                                            placeholder="Width"
                                                            value={formData.width}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <input
                                                            type="text"
                                                            id="height"
                                                            placeholder="Height"
                                                            value={formData.height}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="quote-field inp-y-spc anything-dangerous-fields radio-btns">
                                            <div className="row">
                                                <div className='col-lg-8'>
                                                    <p className='mb-0'>Special Handling</p>
                                                    <div className=" d-flex flex-row">
                                                        <div className='radio-optn-cntnr'>
                                                            <input type="radio" name="handling" id="fragile" onChange={handleRadioChange} />
                                                            <label htmlFor="fragile">Fragile</label>
                                                        </div>
                                                        <div className='radio-optn-cntnr'>
                                                            <input type="radio" name="handling" id="liquid" onChange={handleRadioChange} />
                                                            <label htmlFor="liquid">Liquid</label>
                                                        </div>
                                                        <div className='radio-optn-cntnr'>
                                                            <input type="radio" name="handling" id="un-shape" onChange={handleRadioChange} />
                                                            <label htmlFor="un-shape">Unusual Shape</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 d-flex justify-content-between align-items-center'>
                                                    <select
                                                        className=''
                                                        id="deliveryAddressType"
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="">Delivery Address Type</option>
                                                        <option value="Residential">Residential</option>
                                                        <option value="Commercial">Commercial</option>
                                                        <option value="Air & Sea Depot">Air & Sea Depot</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <LoadingOptions type="delivery" />
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-smth" disabled={isLoading}>
                                    {isLoading ? 'Loading...' : 'Get Quote'}
                                </button>
                            </form>
                        </div>
                        <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">...</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CalQuote;

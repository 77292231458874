import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className='container'>
      <h1>Terms and Conditions</h1>
      <p>TERMS AND CONDITIONS OF TRANSPORT / CARTAGE

Definition
“Company” shall mean CourierLink Pty Ltd (ABN 96681424037) trading as, its employees, servants, agents, subsidiaries and/or associated entities.

“Customer” shall mean the shipper (consignor), the receiver (consignee), the owner of the Goods, the bailor of the Goods or the person for whom any of the Services are performed.

“Goods” shall mean the chattels, articles or things tendered for carriage or bailment or other services by the Customer and shall include the container or containers, unit load devices or other packaging containing the same and any other pallet or pallets delivered with the same to the Company or Subcontractor.

“Services” shall mean the storage/warehousing, carriage, transport, movement, handling, purchase and/or any other service performed or arranged by the Company pursuant to, or ancillary to, this contract with the Customer.

“Dangerous goods” shall mean such of the Goods as shall be, or become, in fact or at law noxious, dangerous, hazardous, explosive, radioactive, inflammable or capable by their nature of causing damage or injury to other goods or to any person or animals or to any thing in which those goods are carried, handled or stored.

“Valuables” shall mean bullion, coins, precious stones, jewellery, antiques, or works of art.

“Perishable goods” shall mean such of the Goods as shall be in fact or law liable to deteriorate in quality and/or value and shall include, but not be limited to, fruits, vegetables, dairy products, meat, etc.

“Prohibited goods” are those Goods strictly prohibited from shipment as defined by the Company on its website under Prohibited Items.

“Subcontractor” shall mean and include: –

(i)Any person, firm or company with whom the Company may arrange to effect any Service in respect of the Goods which are the subject of this contract.

Any person, firm or company which is now or hereafter a servant, agent, employee or independent contractor of any of the persons or entities referred to in (i) above.

Any other person, firm or company (other than the Company) by whom the Services or any part thereof are arranged, performed or undertaken.

Words importing the singular include the plural and vice versa and words importing any gender include all genders and words importing a person include firm and corporation where appropriate.

Not a common carrier
The Company is not a common carrier and accepts no liability as such.  Services are arranged or performed by the Company subject only to these conditions of contract which constitute the entire agreement between the Company and the Customer.  No person has the authority of the Company to waive or vary these conditions and the Company reserves the right to refuse at its sole discretion the carriage of the Goods for any customer or any other Service whether before or after the carriage or Service has commenced and further reserves the right to open and inspect all Goods at its discretion and at the Customer’s expense.

UPS.

All claims against a UPS must be filed in writing with UPS as soon as reasonably practicable and in any event within 14 days of delivery in the case of damage (including partial loss of a shipment) and in the case of delay within 21 days of delivery. In addition, UPS shall have no liability in connection with any shipment unless legal proceedings are brought and written notice of them is given to it within six months after delivery of the goods concerned or, in the case of non-delivery, within six months from the scheduled date for delivery. This term shall not affect any rights the shipper may have under applicable Convention Rules or other applicable mandatory national laws”

DHL Conditions of Carriage (https://mydhl.express.dhl/hu/en/shipment/terms-and-conditions.html)
FedEx Conditions of Carriage (https://www.fedex.com/en-au/conditions-of-carriage.html)
TNT Conditions of Carriage (https://www.tnt.com/express/en_au/site/terms-conditions.html)

Agency / Contracting / Bailment
Subject to and in accordance with the terms and conditions and instructions contained in this contract, the Company agrees and the Customer hereby employs and authorises the Company, as agent only of the Customer to contract either in its own name or in the Customer’s name with any Contractor, and employs and authorises any Contractor to subcontract with any other Contractor, for the performance of any Service to be performed or arranged by the Company pursuant to, or ancillary to, this contract.  Any such contract may be made on any terms of contract whatsoever used by the Contractor with whom the Company or Contractor may contract for such Service(s) including in every case terms which may limit or exclude liability in respect of the Service.

Warehousing
The Goods may at any time be warehoused or otherwise held at any place or at any time be removed from any place at which they may be warehoused or otherwise held to any other place to be warehoused or otherwise held at the sole discretion of the Contractor.  In every case, whether warehousing is incidental or the primary Service provided by the Contractor, it will be provided at the Customer’s risk and expense as a primary charge(s) or a charge(s) incidental to or in connection with the carriage of the Goods or any Service hereunder.

Warranties by the Customer
The Customer warrants:

(a)    that it is the owner of the Goods or otherwise has the authority of the owner or person having an interest in the Goods or any part thereof to consign the goods upon and subject to these conditions.  Without prejudice to the foregoing warranty, the Customer undertakes to indemnify the Company in respect of any liability whatsoever or howsoever caused in respect of the Goods to any person who claims to have, has or may acquire an interest in the Goods or any part thereof.

(b)    that the person releasing or delivering the Goods for collection is authorised to accept these conditions on the Customer’s behalf.

(c)    the accuracy of all markings and brandings of the Goods, descriptions, values and other particulars furnished to the Company for the carriage, customs, consular and any other purposes and undertakes to indemnify the Company against all loss, damage, expenses and fines arising from any inaccuracy or omission in this respect.

(d)    that the performance of any Service provided or arranged by the Company to effect the instructions of the Customer in respect of the Goods shall not be in breach of any law.

Exclusion and Limitation of Liability
(a)     Subject to the terms and conditions in this contract, the Company shall not be liable for any loss or damage suffered by the Customer or any other person, howsoever caused or arising, whether:

(i)          an authorised or unauthorised act OR contemplated or uncontemplated act under this contract;

(ii)        caused by the negligence and/or recklessness and/or will full misconduct of the Company’s servants, agents, employees, Contractors or otherwise;

(iii)    resulting from, or attributable to, any quotation, statement, representation or information, oral or written, made or given on behalf of the Company or its servants, agents, employees or Contractors as to the classification of, liability for, amount, scale or rate of customs duty, excise duty or other impost or tax applicable to any goods subject of any Service.

(b)     No declaration of value will be made for the purpose of extending liability and the Goods will be forwarded or dealt with at the Customer’s or owner’s risk unless express written instructions to the contrary are given by the Customer and accepted in writing by the Company.

(c)    In all cases where liability has not been, or cannot be, excluded by this agreement because of mandatory applicable statute, convention or law, the liability of the Company is limited to the lesser of AUD$100.00 or the value of the Goods the subject of the agreement at the time the Goods were received by the Company.

(d)    In all cases where liability cannot be excluded or limited by this agreement for breach of any condition or warranty in respect of the Services, the liability of the Company is limited to any one or more of the following as determined by the Company at its absolute discretion:

(i) providing supply of the Services again; or

(ii) payment of the cost of having the Services supplied again.

(e)     Without limiting the generality of the foregoing, the Company shall in no circumstances be liable for direct, indirect or consequential loss or damage arising from the Services performed in respect of the Goods including loss of market, loss of profit or loss of contracts howsoever caused.  The rights, immunities, defences and limits provided for in these conditions shall apply in any action against the Company for loss or damage whether the action be found in contract, tort or otherwise notwithstanding any breach of the contract or condition hereof by the Company.

(f)     Further without limiting the generality of the foregoing, the Company shall not be liable for any loss or damage suffered by the Customer or any other person as a result of a failure or inability of the Company or Contractor to collect or receive C.O.D. payments from any consignees or their agents whether caused by the negligence of the Company’s servants, agents, employees, Contractors or otherwise.

(g)    It is hereby agreed between the Customer and the Company that the Customer’s right to compensation for any claim for loss or damage will only be maintained provided the following is strictly adhered to:

(i)  Any claim for damage to Goods must be lodged in writing to the Company within 7 days of delivery of the Goods or the date Services are completed, whichever date occurs first;

(ii)  Any claim for loss/non-delivery of Goods must be notified in writing to the Company within 60 days from the date the Goods should have been delivered or the Services should have been completed, whichever date occurs first;

(iii)  Any right to any legal remedy against the Company shall be extinguished unless legal proceedings are brought against the Company in the state of NSW and not otherwise within 6 months from the date of this contract or the date the Services were completed or Goods delivered, or the date the Services should have been completed or the Goods should have been delivered, whichever date occurs first.

Obligations of the Customer
The Customer shall be responsible for:

(a)  placing its order online with the Company including but not limited to:

(i)  providing a correct description of the Goods,

(ii)  designating the Contractor to perform the Services,

(iii)  advising the places/addresses of collection and delivery of the Goods and

(iv)  advising the telephone number of the receiver of the Goods at the place of delivery (local number at place of delivery).

(b)  ensuring the Goods booked by the Customer are made available for collection by the Contractor at the booked place of collection.

(c)  ensuring the Goods can be and are received by a person at the booked place of delivery.

(d)  ensuring that it prints and attaches the Company’s barcoded label (provided online from its booking) to the Goods in a secure, clear and externally visible position.

(e)  ensuring that it does not ship fragile goods and that the Goods, including their packaging, can withstand a short drop and the normal rigours of carriage and handling.

(e)  tracking all orders from place of collection to place of delivery of the Goods.

(f)  additional charges in respect of residential collections and/or deliveries, remote collections and/or deliveries, futile collections and/or deliveries and overweight or oversized parcels.

(g)  obtaining a hard copy proof of delivery charge at A$10 per item.

(h)  any surcharges or additional costs incurred returning Goods resulting from the Customer’s failure to comply with the above mentioned obligations, including but not limited to the Customer cancelling or missing the booked collection, failure to take delivery at the booked place of delivery or failure to correctly attach the Company’s barcode to the Goods as provided herein.

(i)  any loss or damage that arises from the Company cancelling the Service because of the Customer’s failure to comply with the terms of this Contract, including its failure to pay any surcharges or costs payable hereunder.

Himalaya Clause
In arranging performance of the Services with any Contractor on the Customer’s behalf, the Customer shall have no right of action against the Company in relation to the performance of the Services or any loss or damage that arises therefrom.  Without prejudice to the foregoing, every such Contractor shall have the benefit of all provisions herein benefiting the Company as if such provisions were expressly for its benefit, and in entering into this contract, the Company, to the extent of these provisions, does so not only on its own behalf, but also as agent and trustee for such parties.

Customer’s Indemnity
(a)     The Customer shall indemnify the Company in respect of any claim, loss, damage, payment, fine, expense, duty, tax, impost or other outlay whatsoever or howsoever caused, whether arising directly or indirectly from any Service arranged or performed by the Company in respect of Goods and/or in respect of any such cost incurred as a result of any breach of the terms, conditions or warranties in this contract by the Customer.

(b)    Without limiting the generality of the foregoing, the Customer shall remain responsible to the Company for all charges (C.O.D. or otherwise) paid by the Company to any of its agents, Contractors or any other party or authority.

(c)    The Customer shall indemnify the Company in respect of any loss or damage arising from any inherent defect, quality or vice of the Goods.

Warranty
Our Liability for loss or damage is limited subject to the Terms & Conditions of the FreightSafe Warranty, which can be found here.

Quotations
Quotations for the Services are made on an immediate acceptance basis and are subject to withdrawal or revision without notice at the Company’s discretion.

Routes and Procedures
If the Company is instructed by the Customer and agrees to use a particular method, mode or route of transport and/or Service, the Company shall give due consideration to the method, mode or route designated but shall at all times have the right to choose or vary such method or mode of transport and/or Service or route and procedure to be followed in respect of the Service performed.  The Customer hereby authorises the Company to substitute alternate carriers or other Service providers without notice to the Customer.

Payment of Expenses/Duties & Release of Information
The Customer authorises the Company and Contractors in effecting the Services, but with no obligation on the part of the Company or Contractors, at any port or place to:

(a)  pay any duties, taxes, imposts, outlays or charges in respect of the Goods and/or Services

(b)  to release or allow inspection of the Goods or any information and/or documents of the Customer, the Goods, the Services or relating thereto as required by authorities,

and the Customer shall indemnify the Company in respect of any disbursement, expense, cost, loss, fine or damage incurred by the Company or Contractors in doing so and releases the Company and Contractors from any liability in connection therewith.

Responsibility for Fees/Charges
(a)  The Customer shall pay the Company for all fees rendered and any charges it incurs for any reason in respect of the Services performed.  This includes the payment of fees/charges which the Company is advised or agrees will be paid by a third party which then fails to so pay.  Such fees/charges shall be deemed fully earned as soon as the Goods are loaded and dispatched from the Customer’s premises, otherwise delivered by the Customer to the Company or Subcontractor or on receipt of the Company’s invoice whichever occurs first and shall be immediately payable and non-refundable.

(b)  The Customer agrees that it shall not defer or withhold payment or deduct any amount from the account of the Company by reason of any claim it alleges against the Company.

(c)  Provision of Credit by the Company to the Customer may be suspended by the Company at its own discretion if fees and charges invoiced are overdue or otherwise.  The Customers shall pay the Company interest at the National Australia Bank Trading Bank overdraft rate plus 5% in respect of any overdue fees or charges invoiced.

Lien – Security Agreement – Personal Properties Security Act
The Company shall have a particular and general lien on the Goods of the Customer and any documents relating thereto for all sums payable by the Customer to the Company. The Customer agrees that these terms and conditions constitute a security agreement for the purpose of the Personal Properties Security Act (2009)(“PPSA”) and create a security interest in all Goods and documents relating thereto of the Customer to the extent that the Company and Customer agree by way of this agreement that the Company has the right to:

(a)     exercise a general lien over all Goods and documents of the Customer in respect of any moneys owed by the Customer to the Company and

(b)    sell the Goods or a portion thereof and direct the proceeds of sale to payment to the Company of moneys owed by the Customer to the Company and remit any balance remaining (if such exists) to the Customer;

provided that the Company will notify the Customer and all other relevant persons of its intention to sell the goods in accordance with the requirements of mandatorily applicable legislation.  If no such legislation applies, the Company will exercise its right to sell the goods or a portion thereof after a period of 7 days from the date which the Company notifies the Customer that it is exercising its rights of sale.

Registration/Personal Properties Security Act
(a) The Customer agrees to:

(i)sign any documents or provide further documents or information required by the Company to register a financing statement or financing change statement in respect of a security interest with the Personal Property Securities Register, register any other documents required by the PPSA or correct any such document(s).

(ii)  indemnify the Company for all expenses incurred in attending to the steps outlined in (i) above and releasing any interests from the Register.

(v)   not register a financing change statement in respect of a security interest without prior written consent by the Company.

(iv)  not register, nor permit to be registered, a financing statement or a financing change statement in relation to the Goods in favour of a third party without the prior written consent of the Company

(b)  The Company and Customer agree that sections 96, 117 and 125 of the PPSA do not apply to the security agreement created by these terms and conditions.

(c)   The Customer hereby waives its rights to receive notices or statements under sections 95, 118, 121(4),123, 130, 132(3)(d), 132(4), 135 and 157 of the PPSA.

(d)  The Customer waives its rights as a grantor and/or a debtor under sections 142 and 143 of the PPSA.

(e)   The Customer warrants that it has rights in the Goods the power to transfer rights in the Goods to the Company and will not grant any other person a security interest in respect of the Goods.

(f)   The Customer agrees to ratify unconditionally any actions taken by the Company pursuant to registration related requirements under the PPSA and as referred in clause 16(a) above.

Dangerous Goods, Prohibited and/or Restricted Goods
Paul McEwan, [15/03/2022 12:22 PM]

(a)(i)    Except as agreed in writing, the Company will not accept Dangerous Goods, Prohibited and/or Restricted Goods for Services arranged or performed by the Company.  Should the Customer nevertheless deliver any such goods to the Company or cause the Company to handle or deal with any such goods otherwise than as agreed in writing, the Customer (not the Company) shall be liable for any loss, damage or cost thereto or consequent thereon whether direct, indirect or consequential and howsoever caused and the Customer shall indemnify the Company from and against all penalties, taxes, duties, claims, demands, damages, costs and expenses arising in connection therewith.

(ii)   Any such goods may be destroyed, returned or sold in the sole and absolute discretion of the Company or any other person in whose custody they may be at the relevant time.  In the event that the goods are destroyed or otherwise dealt with as aforesaid, the Company shall bear no liability and the Customer shall indemnify the Company from and against all costs and expenses incurred with respect thereto.

(b)  The Customer undertakes that any of the goods referred to in (a) above (including their covering, packaging, containers and other carriage devices) shall be distinctly marked having regard to their nature.  The Customer further undertakes that the Goods are packed in a manner adequate to withstand the ordinary risks of any Service having regard to their nature and in compliance with all laws and regulations which may be applicable with respect to any Service.  The Customer shall indemnify the Company against all claims, losses, damages or expenses arising in consequence of any breach of this provision.

(c)  The Customer’s compliance with (b) above in no way reduces or limits those rights afforded to the Company under (a) of this clause.

Sale and Disposal of Goods
The Company and its Contractors shall be entitled at the cost and expense of the Customer, subject to any compliance with any applicable law, to sell or dispose of:

(a)  Goods which in the opinion of the Company or Contractor cannot be delivered by reason of the Goods being insufficiently or incorrectly addressed or by reason of the Goods not being collected or accepted by the Consignee or for any other reason, and

(b)  any Perishable goods which in the opinion of the Company or the Contractor appear to be deteriorating, if the Customer fails to adequately instruct the Company with respect thereto or fails to pay any costs and expenses necessary to implement the Customer’s instructions

Collection / Delivery / Completion / Tracking
(a)  The Company is authorised to arrange collection and delivery of the Goods in accordance with the Customer’s booking.

(b)  If the nominated place of delivery shall be unattended or if delivery cannot otherwise be effected, the Company in its sole discretion may at its option either deposit the Goods at the nominated place, store or return the Goods at the risk and expense of the Customer, all of which will be deemed to be delivery of the Goods under this Contract.

(c)  Times and Dates specified for collection, delivery and completion in respect of any of the Services are estimates only and the Company shall not be liable for failure to complete any of the Services within or on such times or dates so specified.

(d)  The Company provides a tracking service on its website which discloses information provided by the Contractors.  The Company does not guarantee the performance or accuracy of this service or the information disclosed and shall not be liable for any loss and/or damage whatsoever arising from any failure/inaccuracy in respect thereof.

(e)  If The Company cannot provide the service to The Customer as paid for, The Company will provide a full refund. If The Customer requires a cancellation of the service as paid for prior to collection (or attempted collection) The Company will provide a refund of the services paid for less a $10 administration fee

Regulation Compliance
The Customer shall comply with all applicable laws and Government regulations and directions including those relating to the packing, carriage, storage, customs clearance, delivery, inspection or other Services in respect of the Goods, and shall furnish such information and provide such documents as may be necessary to comply with such laws and regulations. The Company shall not be liable to the Customer for loss or expense due to the Customer’s failure to comply with this provision and will indemnify the Company for any expense incurred by the Company in so complying.

Customer’s Own Form
The use of the Customer’s own form is no derogation to these conditions of contract.

Representations
By accepting these conditions, the Customer agrees that it did not rely on any representation, promise, warranty or condition of the Company or its Subcontractor not expressly made (in writing) part of this contract.

Severance & Waiver
It is hereby agreed that if any provision or part of any provision of this contract is unenforceable, such unenforceability shall not affect any other part of such provision or any other provision hereof.  Further, should the Company elect not to exercise any of its rights under this contract, under any other contract/agreement or under law, such election shall not constitute a waiver of any rights relating to any other or subsequent breach by the Customer.

Law and Jurisdiction
Any dispute arising under this Contract shall be governed by the laws of New South Wales and shall be determined exclusively by the courts of NSW or by the court of the Company’s choice.

Trade Practices
(a)  Any relief from liability contained in this agreement is to be read subject to any restriction on contracting out of liability provided in any legislation binding on the Company so that the provisions for relief contained in this agreement are limited or rendered ineffective only to the extent required to give effect to that legislation but are otherwise fully effective and all the provisions hereof are severable and effective independently of any provisions which are null and void or ineffective by reason of any legislation.

(b)  Unless written notification to the contrary is given by the Customer to the Company at or prior to entering into this agreement, the Customer expressly warrants and represents that all or any Services to be supplied by the Company and acquired by the Customer pursuant to this agreement are so supplied and acquired for the purposes of a business, trade, profession or occupation carried on or engaged in by the Customer.

Force Majeure
Where the Company is unable to carry out any obligation under the contract due to any circumstance, matter or thing beyond its reasonable control (“force majeure”), the Company shall be excused from such obligations to the extent of such prevention, restriction or interference so caused.

Currency
All transactions are processed in AUD (Australian Dollars).

 </p>
      {/* Add more details about the terms and conditions here */}
    </div>
  );
};

export default TermsAndConditions;

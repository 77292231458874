import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='container'>
      <h1>Privacy Policy</h1>
      <p>About this Privacy Policy 

This policy is written in simple language. The specific legal obligations of CourierLink Pty Ltd when collecting and handling your personal information are outlined in this Privacy Policy. We will review and update this privacy policy when our information practices change. Updates will be published on our website and through additional channels such as email or through social media. 

Overview 

CourierLink Pty Ltd may collect, hold, use and disclose personal information to carry out functions or activities under the Australian Information Commissioner Act 2010 (AIC Act), the  Privacy Act) and the Freedom of Information Act 1982 (FOI Act. 

These functions and activities include:                                                         

consulting with customers and users of our system, for example, on privacy, FOI 
responding to access to information requests 
communicating with the public, stakeholders and the media including through websites and social media 
Collection of your personal information 

At all times CourierLink Pty Ltd endevour to only collect the information we need for the particular function or activity we are carrying out. 

The main method to collect personal information about you is when you give it to us. For example, we collect personal information such as contact details, order placement, or via other lists we collate during the course of marketing campaigns. These are not limited to, although could be relevant to; 

contacting us to ask for information (but only if we need it) 
making a complaint 
ask for access to information CourierLink Pty Ltd holds about you or other information  
CourierLink Pty Ltd may also collect information from you when we investigate or review an order or shipment through one of our carrier partners.  

We may also collect contact details and some other personal information if you are participating in a meeting or in consultation with us. 

Collecting sensitive information 

Sometimes CourierLink Pty Ltd may need to collect sensitive information about you, for example, to investigate a freight booking, such as location, time, observations. 

In the course of handling and resolving a complaint, review or an investigation into late freight delivery or missing freight, we may collect personal information (including sensitive information) about you indirectly from publicly available sources or from third parties such as: 

your authorised representative, if you have one 
ASIC 
applicants, respondents to a complaint, investigation, application or third party carriers and warehousing logistics providers employees, witnesses and freight forwarders. 
CourierLink Pty Ltd also collects personal information from publicly available sources to enable us to contact stakeholders who may be interested in our work or in participating in our services. 

Anonymity 

Where possible, we will allow you to interact with us anonymously or using a pseudonym. For example, if you contact CourierLink Pty Ltd with a general question, we may ask for your name and job order number to adequately respond to your query. 

However, for most of our functions and activities we usually need your name, contact information, job booking number and enough information about the particular matter to enable us to fairly and efficiently handle your inquiry, request, complaint or to act on your behalf. 

Collecting through our websites 

The CourierLink Pty Ltd public website, oneworldcourier.com.au is hosted in Australia. There are a number of ways in which we collect information through our website. 

Web analytics 

We use Google Analytics, Google Ads, Facebook, Bing and WPEngine to collect data about your interaction with our website. The main purpose of collecting your data in this way is to improve your experience when using our website. We also use this data to understand and report on which content pages and downloads are accessed by visitors. 

The types of data we collect with these tools include: 

your device’s IP address (collected and stored in an anonymized format) 
search terms and pages visited on our website 
date and time when pages were accessed 
downloads, time spent on page, and bounce rate 
referring domain and out link if applicable 
referring ad network, campaign id’s and source 
device type, operating system and browser information 
device screen size 
geographic location (city). 
gender and age 
time on site 
If your web browser has ‘cookies’ disabled your visits to our website will not be tracked.  

Cookies 

Cookies are small data files transferred onto computers or devices by websites for record-keeping purposes and to enhance functionality on our website. Most browsers and operating systems (iOS and Android included) allow you to choose whether to accept cookies or not. If you do not wish to have cookies placed on your computer, please set your browser preferences to reject all cookies before accessing our website. 

Embedded videos on our website 

We use Google’s YouTube site to host videos which are embedded on our website. Embedded videos on our website. When you play an embedded video from our website, the video and associated assets will load from the domain https://www.youtube.com/ and/or other domains associated with Google’s YouTube player. 

YouTube collects information about user activity including videos watched and interactions with content and ads. These analytics are made available to CourierLink Pty Ltd, and we use this information to understand how our videos perform. You can access the privacy policy for YouTube on the Google website. 

Email lists, registrations and feedback 

We will collect information that you provide to us when signing up to mailing lists, software trials, or when submitting feedback on your experience with our website. 

We use Active Campaign to manage our mailing lists and marketing activities. When subscribing to one of our mailing lists, you will be asked to give your express consent that CourierLink Pty Ltd may use your data for analytics purposes. Analytics are performed when you click on links in the email, or when you download the images in the email. They include which emails you open, which links you click, your mail client (e.g. ‘Outlook, GMail’), if your action occurred on ‘mobile’ or ‘desktop’, and the country geolocation of your IP address (the IP address itself is not stored). 

We use the services of Full Story to collect voluntary feedback on your experience with our website. We also Full Story to conduct anonymous surveys to gather feedback to help us improve our performance. We do not collect personal information via Full Story. Information about how Full Story manages personal information is available in the privacy and data collection policies on its website. 

Social networking services 

We use social networking services such as Twitter, Facebook, LinkedIn and YouTube to communicate with the public about which services we deliver. When you communicate with us using these online services we may collect your personal information, but we only use it to help us to communicate with you. The social networking services we use will also handle your personal information for its own purposes. These services have their own privacy policies. You can access the privacy policies for Twitter,  LinkedIn and YouTube (a Google company) on their websites. 

Website forms 

CourierLink Pty Ltd uses the forms provided by Google and Active Campaign to enable you to lodge an enquiry, apply for a trial of our software or track a shipment. 

When you save or submit a form using this service it is encrypted and stored in a secure server located in Australia. CourierLink Pty LTD can view your information and can share it throughout the organisation to improve customer service and the overall experience of our users. CourierLink Pty LTD may store customer credit card numbers on file, although these details are encrypted and stored on a secure server with Amazon Web Services (AWS). 

This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.

Disclosure 

Common situations in which we disclose information are detailed below. 

Disclosure to service providers 

The OAIC uses a number of service providers to whom we disclose personal information. These include carriers who partner with us and manage our IT services. 

Disclosure of sensitive information 

We only disclose your sensitive information for the purposes for which you gave it to us or for directly related purposes you would reasonably expect or if you agree, for example, to handle a query. 

Quality of personal information 

To ensure that the personal information we collect is accurate, up-to-date and complete we: 

record information in a consistent format 
where necessary, confirm the accuracy of information we collect from a third party or a public source 
promptly add updated or new personal information to existing records 
regularly audit our contact lists to check their accuracy. 
We also review the quality of personal information before we use or disclose it. 

Storage and security of personal information 

All personal information collected is held on our cloud storage, on servers located with Amazon Web Services (AWS) and WPEngine in Australia. We retain effective control over any personal information held on our cloud. 

We take steps to protect the security of the personal information we hold from both internal and external threats by: 

regularly assessing the risk of misuse, interference, loss, and unauthorised access, modification or disclosure of that information 
taking measures to address those risks, for example, we keep a record (audit trail) of when someone has added, changed or deleted personal information held in our electronic databases and regularly check that staff only access those records when they need to 
conducting regular internal and external audits to assess whether we have adequately complied with or implemented these measures. 
We delete personal information in a secure manner when we no longer need it.

Accessing and correcting your personal information 

Under the Privacy Act (APPs 12 and 13) you have the right to ask for access to personal information that we hold about you, and ask that we correct that personal information. You can ask for access or correction by contacting us and we must respond within 30 days. If you ask, we must give you access to your personal information, and take reasonable steps to correct it if we consider it is incorrect, unless there is a law that allows or requires us not to. 

We will ask you to verify your identity before we give you access to your information or correct it, and we will try to make the process as simple as possible. If we refuse to give you access to, or correct, your personal information, we must notify you in writing, setting out the reasons. 

The steps appropriate to verify an individual’s identity will depend on the circumstances. We will seek the minimum amount of personal information needed to establish an individual’s identity. For example, during a telephone contact it may be adequate for us to request information that can be checked against our records. 

If we make a correction and we have disclosed the incorrect information to others, you can ask us to tell them about the correction. We must do so unless there is a valid reason not to. 

If we refuse to correct your personal information, you can ask us to associate with it (for example, attach or link) a statement that you believe the information is incorrect and why. 

How to make a complaint or enquiry 

If you wish to enquore about how CourierLink Pty Ltd have handled your personal information, you should first contact us in writing. If we receive an enquiry from you about how we have handled your personal information, we will determine what (if any) action we should take to resolve the issue. 

If we decide that your enquiry should be investigated further, this will usually be handled by a senior manager of CourierLink Pty LTD. We will contact you promptly that we have received your enquiry, then respond within 30 days. 

 </p>
      {/* Add more details about the privacy policy here */}
    </div>
  );
};

export default PrivacyPolicy;

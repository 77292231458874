import React, { useState, useContext } from 'react';
import QSidebar from './QSidebar';
import EDD from '../assets/EDD-lgt.svg';
import CollectionDate from '../assets/Box-lgt.svg';
import Warranty from '../assets/warranty-lgt.svg';
import { QuoteContext } from '../assets/context/QuoteContext';
import { useNavigate } from 'react-router-dom';

import validator from 'validator';

const SRDetails = () => {
    const navigate = useNavigate();

    const { selectedQuote, saveSRDetails } = useContext(QuoteContext);

    const [showSenderForm, setShowSenderForm] = useState(true);
    const [senderFormData, setSenderFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        pickupInstructions: '',
    });
    const [receiverFormData, setReceiverFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        pickupInstructions: '',
    });
    const [senderErrors, setSenderErrors] = useState({});
    const [receiverErrors, setReceiverErrors] = useState({});

    const handleSenderFormChange = (e) => {
        setSenderFormData({
            ...senderFormData,
            [e.target.name]: e.target.value,
        });
        setSenderErrors((prevErrors) => ({
            ...prevErrors,
            [e.target.name]: '',
        }));
    };

    const handleReceiverFormChange = (e) => {
        setReceiverFormData({
            ...receiverFormData,
            [e.target.name]: e.target.value,
        });
        setReceiverErrors((prevErrors) => ({
            ...prevErrors,
            [e.target.name]: '',
        }));
    };

    const validateSenderForm = () => {
        const errors = {};

        if (!validator.isLength(senderFormData.firstName.trim(), { min: 1 })) {
            errors.firstName = 'First Name can\'t be empty';
        }

        if (!validator.isLength(senderFormData.lastName.trim(), { min: 1 })) {
            errors.lastName = 'Last Name can\'t be empty';
        }

        if (!validator.isEmail(senderFormData.email)) {
            errors.email = 'Please enter a valid email address';
        }

        // if (!validator.isMobilePhone(senderFormData.phone.trim(), 'en-US')) {
        //     errors.phone = 'Please enter a valid 10-digit phone number';
        // }

        if (!validator.isLength(senderFormData.address1.trim(), { min: 1 })) {
            errors.address1 = 'Address Line 1 can\'t be empty';
        }

        if (!validator.isLength(senderFormData.address2.trim(), { min: 1 })) {
            errors.address2 = 'Address Line 2 can\'t be empty';
        }

        if (!validator.isLength(senderFormData.pickupInstructions.trim(), { min: 1 })) {
            errors.pickupInstructions = 'Pickup Instructions can\'t be empty';
        }

        return errors;
    };

    const validateReceiverForm = () => {
        const errors = {};

        if (!validator.isLength(receiverFormData.firstName.trim(), { min: 1 })) {
            errors.firstName = 'First Name can\'t be empty';
        }

        if (!validator.isLength(receiverFormData.lastName.trim(), { min: 1 })) {
            errors.lastName = 'Last Name can\'t be empty';
        }

        if (!validator.isEmail(receiverFormData.email)) {
            errors.email = 'Please enter a valid email address';
        }

        // if (!validator.isMobilePhone(receiverFormData.phone.trim(), 'en-US')) {
        //     errors.phone = 'Please enter a valid 10-digit phone number';
        // }

        if (!validator.isLength(receiverFormData.address1.trim(), { min: 1 })) {
            errors.address1 = 'Address Line 1 can\'t be empty';
        }

        if (!validator.isLength(receiverFormData.address2.trim(), { min: 1 })) {
            errors.address2 = 'Address Line 2 can\'t be empty';
        }

        if (!validator.isLength(receiverFormData.pickupInstructions.trim(), { min: 1 })) {
            errors.pickupInstructions = 'Pickup Instructions can\'t be empty';
        }

        return errors;
    };

    const handleNextClick = () => {
        const errors = validateSenderForm();
        setSenderErrors(errors);
        if (Object.keys(errors).length === 0) {
            setShowSenderForm(false);
        }
    };

    const handleBackClick = () => {
        setShowSenderForm(true);
    };

    const handleReviewAndPayClick = () => {
        const senderErrors = validateSenderForm();
        const receiverErrors = validateReceiverForm();
        setSenderErrors(senderErrors);
        setReceiverErrors(receiverErrors);
        if (Object.keys(senderErrors).length === 0 && Object.keys(receiverErrors).length === 0) {
            // Proceed with review and payment
            saveSRDetails({
                senderFormData,
                receiverFormData,
            });
            navigate('/Review');

        }
    };

    return (
        <div className="container mt-5">
            {/* <h2>Compare Quotes and Book Now</h2> */}
            <div className="row mt-4 col-rev-992">
                <div className="col-lg-8">
                    <div className="container my-3">
                        <div className="cesseninfo">
                            <div className="d-flex justify-content-around py-2 sCourierInfo">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="edd-icon">
                                        <img src={EDD} alt="" />
                                    </div>
                                    <span>EDD {selectedQuote.estimatedDelivery}</span>
                                </div>
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="edd-icon">
                                        <img src={CollectionDate} alt="" />
                                    </div>
                                    <span>{selectedQuote.collectionDate}</span>
                                </div>
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="edd-icon">
                                        <img src={Warranty} alt="" />
                                    </div>
                                    <span>{selectedQuote.warranty} of warranty Included</span>
                                </div>
                            </div>
                        </div>
                        <div className="sr-details-container">
                            <div className="sender-details">
                                <div className="d-flex">
                                    <div className="sender-heading">
                                        <h3>{showSenderForm ? 'Sender Details (From)' : 'Receiver Details (To)'}</h3>
                                    </div>
                                    {/* <div className="sender-type d-flex align-items-center">
                                        <div className="radio-optn-cntnr">
                                            <input type="radio" name="caution" id="individual" />
                                            <label htmlFor="individual">Individual</label>
                                        </div>
                                        <div className="radio-optn-cntnr">
                                            <input type="radio" name="caution" id="individual" />
                                            <label htmlFor="individual">Company</label>
                                        </div>
                                    </div> */}
                                </div>
                                {showSenderForm && (
                                    <div className="sender-form mt-5">
                                        {Object.values(senderErrors).map((error, index) => (
                                            <p key={index} className="mt-2 color-red">{error}</p>
                                        ))}
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    name="firstName"
                                                    id="firstName"
                                                    placeholder="First Name"
                                                    value={senderFormData.firstName}
                                                    onChange={handleSenderFormChange}
                                                    className={senderErrors.firstName ? 'border-red' : ''}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    name="lastName"
                                                    id="lastName"
                                                    placeholder="Last Name"
                                                    value={senderFormData.lastName}
                                                    onChange={handleSenderFormChange}
                                                    className={senderErrors.lastName ? 'border-red' : ''}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Email"
                                                    value={senderFormData.email}
                                                    onChange={handleSenderFormChange}
                                                    className={senderErrors.email ? 'border-red' : ''}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-lg-4">
                                                <input
                                                    type="tel"
                                                    name="phone"
                                                    id="phone"
                                                    placeholder="Sender's Phone"
                                                    value={senderFormData.phone}
                                                    onChange={handleSenderFormChange}
                                                    className={senderErrors.phone ? 'border-red' : ''}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    name="address1"
                                                    id="address1"
                                                    placeholder="Address Line 1"
                                                    value={senderFormData.address1}
                                                    onChange={handleSenderFormChange}
                                                    className={senderErrors.address1 ? 'border-red' : ''}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    name="address2"
                                                    id="address2"
                                                    placeholder="Address Line 2"
                                                    value={senderFormData.address2}
                                                    onChange={handleSenderFormChange}
                                                    className={senderErrors.address2 ? 'border-red' : ''}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-lg-12">
                                                <input
                                                    type="text"
                                                    name="pickupInstructions"
                                                    id="PIns"
                                                    placeholder="Pickup instructions"
                                                    value={senderFormData.pickupInstructions}
                                                    onChange={handleSenderFormChange}
                                                    className={senderErrors.pickupInstructions ? 'border-red w-100' : 'w-100'}
                                                />
                                            </div>
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-smth mt-5"
                                            onClick={handleNextClick}
                                        >
                                            Next Receiver Details
                                        </button>
                                    </div>
                                )}
                                {!showSenderForm && (
                                    <div className="reciever-form mt-5">
                                        {Object.values(receiverErrors).map((error, index) => (
                                            <p key={index} className="mt-2 color-red">{error}</p>
                                        ))}
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    name="firstName"
                                                    id="firstName"
                                                    placeholder="First Name"
                                                    value={receiverFormData.firstName}
                                                    onChange={handleReceiverFormChange}
                                                    className={receiverErrors.firstName ? 'border-red' : ''}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    name="lastName"
                                                    id="lastName"
                                                    placeholder="Last Name"
                                                    value={receiverFormData.lastName}
                                                    onChange={handleReceiverFormChange}
                                                    className={receiverErrors.lastName ? 'border-red' : ''}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Email"
                                                    value={receiverFormData.email}
                                                    onChange={handleReceiverFormChange}
                                                    className={receiverErrors.email ? 'border-red' : ''}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-lg-4">
                                                <input
                                                    type="tel"
                                                    name="phone"
                                                    id="phone"
                                                    placeholder="Receiver's Phone"
                                                    value={receiverFormData.phone}
                                                    onChange={handleReceiverFormChange}
                                                    className={receiverErrors.phone ? 'border-red' : ''}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    name="address1"
                                                    id="address1"
                                                    placeholder="Address Line 1"
                                                    value={receiverFormData.address1}
                                                    onChange={handleReceiverFormChange}
                                                    className={receiverErrors.address1 ? 'border-red' : ''}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    name="address2"
                                                    id="address2"
                                                    placeholder="Address Line 2"
                                                    value={receiverFormData.address2}
                                                    onChange={handleReceiverFormChange}
                                                    className={receiverErrors.address2 ? 'border-red' : ''}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-lg-12">
                                                <input
                                                    type="text"
                                                    name="pickupInstructions"
                                                    id="DIns"
                                                    placeholder="Pickup instructions"
                                                    value={receiverFormData.pickupInstructions}
                                                    onChange={handleReceiverFormChange}
                                                    className={receiverErrors.pickupInstructions ? 'border-red w-100' : 'w-100'}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <button
                                                style={{ marginRight: '1rem' }}
                                                type="submit"
                                                className="btn btn-stroked mt-5"
                                                onClick={handleBackClick}
                                            >
                                                Back
                                            </button>
                                            <button type="submit" className="btn btn-smth mt-5" onClick={handleReviewAndPayClick}>
                                                Review & Pay
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <QSidebar />
                </div>
            </div>
        </div>
    );
};

export default SRDetails;